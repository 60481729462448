<div class="book-names">
	{#each bookNames as name}
		<div class="book" style="background-color: { getBookColor(name.id) }">
			<StateLink state="main.text" params="{ { book: name.id } }">
				<div class="short-name">
					{name.short}
				</div>
				<div class="full-name">
					{name.full}
				</div>
			</StateLink>
		</div>
	{/each}
</div>

<style>.book-names {
	padding: 4px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	    flex-wrap: wrap;
	-ms-flex-line-pack: start;
	    align-content: flex-start;

	font-family: sans-serif;
}
.book {
	height: 15vw;
	-ms-flex-preferred-size: 25%;
	    flex-basis: 25%;
	white-space: nowrap;

	outline: 1px solid grey;
}
.book :global(a) {
		width: 100%;
		height: 100%;

		color: #333;

		-webkit-text-decoration-line: none;

		        text-decoration-line: none;

		display: -webkit-box;

		display: -ms-flexbox;

		display: flex;
		-webkit-box-align: center;
		    -ms-flex-align: center;
		        align-items: center;
		-webkit-box-pack: center;
		    -ms-flex-pack: center;
		        justify-content: center;
	}

.short-name {
	display: none;
}

@media (max-width: 550px) {
	.short-name {
		display: inherit;
	}

	.full-name {
		display: none;
	}
}
@media (min-width: 1000px) {
	.book {
		-ms-flex-preferred-size: 20%;
		    flex-basis: 20%;
		height: 20vh;
	}
}
@media (min-width: 1300px) {
	.book {
		-ms-flex-preferred-size: 16.6%;
		    flex-basis: 16.6%;
	}
}
</style>

<script>
import books from 'books-of-the-bible'

import bookColors from 'lib/book-of-the-bible-colors.js'
import { getBookId } from 'lib/get-id.js'

import StateLink from 'component/StateLink.html'

export default {
	data() {
		return {
			books
		}
	},
	computed: {
		bookNames: ({ books }) => books.map(({ name, aliases }) => {
			const useFirstAliasForShort = name.length > 5 && aliases[0]

			return {
				full: name,
				short: useFirstAliasForShort ? aliases[0] : name,
				id: getBookId(name)
			}
		}),
	},
	helpers: {
		getBookColor: id => bookColors[id]
	},
	components: {
		StateLink
	}
}
</script>
