<svelte:head>
	<title>{bookName}</title>
</svelte:head>

<div class="container">
	<h1 class="book-name-header">
		{bookName}
	</h1>


	<div class="text-container" ref:textContainer>
		{#each bookSections as section}
			{#if section.type === 'header'}
				<h3>{section.value}</h3>
			{:elseif section.type === 'break'}
				<hr>
			{:elseif section.type === 'paragraph'}
				<p>
					<TextSectionChildren children="{section.children}" {highlightedRange} />
				</p>
			{:elseif section.type === 'stanza'}
				<blockquote>
					<TextSectionChildren children="{section.children}" {highlightedRange} />
				</blockquote>
			{:else}
				<h1>WAT BROKEN</h1>
			{/if}
		{/each}
	</div>
</div>

<RightMarginChapterNumbers {chapterNumbers} {currentChapter} />

<div class="sticky-footer">
	<div class="book-notch">
		<StateLink state="main.book-selection" className="bigger-link">Books</StateLink>
	</div>
</div>

<style>.sticky-footer {
	position: fixed;
	left: 0;
	bottom: 0;
	right: 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
}


.book-notch {
	background-color: #02277F;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;

	-webkit-clip-path: polygon(0 100%, 25% 0, 75% 0, 100% 100%);

	        clip-path: polygon(0 100%, 25% 0, 75% 0, 100% 100%);
}


.book-notch :global(a) {
		padding: 4px 48px;
		color: #fafafa;
	}

.book-name-header {
	text-align: center;
}

.container {
	padding-left: .5rem;
	padding-right: .5rem;
}

@media screen and (min-width: 600px) {


	.container {
		padding-left: 1rem;
		padding-right: 1rem;
	}
}

@media screen and (min-width: 1000px) {


	.container {
		padding-left: 2rem;
		padding-right: 2rem;
	}
}

.container {
	padding-top: .5rem;
	padding-bottom: .5rem;
}

@media screen and (min-width: 600px) {


	.container {
		padding-top: 1rem;
		padding-bottom: 1rem;
	}
}

@media screen and (min-width: 1000px) {


	.container {
		padding-top: 2rem;
		padding-bottom: 2rem;
	}
}

:global(.bigger-link) {
	font-size: x-large;
	font-family: sans-serif;
}

.text-container {
	max-width: 600px;
	margin-left: 32px;
	margin-right: 32px;
}
</style>

<script>
import range from 'just-range'
import svelteQuerystringRouter from 'svelte-querystring-router'
const { attachQuerystringData, getCurrentParameters } = svelteQuerystringRouter

import StateLink from 'component/StateLink.html'

import { getChapterNumberId } from 'lib/get-id.js'
import { fromRange } from 'lib/simple-range.js'

import TextSectionChildren from './TextSectionChildren.html'
import RightMarginChapterNumbers from './RightMarginChapterNumbers.html'

function observeIntersectionsWithTopOfViewport(elements, cb) {
	const viewportTopObserver = new IntersectionObserver(entries => {
		const intersectingEntries = entries.filter(entry => entry.isIntersecting)
		cb(intersectingEntries)
	}, {
		rootMargin: '0px 0px -95%',
		threshold: 0.1
	})

	Array.from(elements).forEach(element => viewportTopObserver.observe(element))

	return viewportTopObserver
}

export default {
	data() {
		return {
			bookName: null,
			bookSections: null,
			currentChapter: null,
			querystringParameters: getCurrentParameters()
		}
	},
	oncreate() {
		attachQuerystringData(this)

		const textElements = this.refs.textContainer.querySelectorAll('.verse-text')
		const viewportTopObserver = observeIntersectionsWithTopOfViewport(textElements, entries => {
			const currentChapter = entries.reduce((currentChapter, entry) => {
				const data = entry.target.dataset
				const entryChapter = parseInt(data.chapterNumber, 10)

				return Math.max(currentChapter, entryChapter)
			}, 0)

			currentChapter && this.set({ currentChapter })
		})

		this.viewportTopObserver = viewportTopObserver
	},
	ondestroy() {
		this.viewportTopObserver.disconnect()
	},
	computed: {
		chapterNumbers: ({ chapterCount }) => range(1, chapterCount + 1),
		highlightedRange: ({ querystringParameters }) => querystringParameters.highlight
			? fromRange(querystringParameters.highlight)
			: null

	},
	helpers: {
		getChapterNumberId
	},
	components: {
		StateLink,
		RightMarginChapterNumbers,
		TextSectionChildren
	}
}
</script>
