<svelte:head>
	<title>Canon Reader</title>
</svelte:head>

<uiView></uiView>

<div class="footer">
	<StateLink state="main">Home</StateLink>
</div>

{#if showReferenceSearch}
<ClickWatcher
	on:clickExternal="onClickOutsideSearch()"
>
	<div class="search-container" style={searchContainerStyle}>
		<ReferenceSearch
			bind:show="showReferenceSearch"
			{mediator}
			{currentBookId}
			autofocus={true}
		/>
	</div>
</ClickWatcher>
{/if}

<style>.search-container {
	position: fixed;
	bottom: 96px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	width: 100%;
}

.footer {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;

	padding-top: 8px;
	padding-bottom: 8px;
	padding-left: .5rem;
	padding-right: .5rem;
}

@media screen and (min-width: 600px) {

	.footer {
		padding-left: 1rem;
		padding-right: 1rem;
	}
}

@media screen and (min-width: 1000px) {

	.footer {
		padding-left: 2rem;
		padding-right: 2rem;
	}
}
</style>

<script>
import ReferenceSearch from 'component/ReferenceSearch.html'
import StateLink from 'component/StateLink.html'
import ClickWatcher from 'svelte-panel-click'

export default {
	oncreate() {
		const provideSync = this.get().mediator.provideSync

		const providerRemovers = [
			provideSync('position search box', position => {
				console.log('position search box called with', position)
				this.manualPosition = position
			}),
			provideSync('unposition search box', () => {
				this.manualPosition = null
			}),
			this.removeNavigationProvider = provideSync(
				'show navigation input',
				currentBookId => {
					this.set({
						showReferenceSearch: true,
						currentBookId,
					})
				}
			),
		]

		this.removeProviders = () => providerRemovers.forEach(remove => remove())
	},
	computed: {
		searchContainerStyle: ({ manualPosition }) => {
			return manualPosition
				? `
					position: absolute;
					top: ${manualPosition.top};
					left: ${manualPosition.left};
				`
				: ``
		}
	},
	methods: {
		onClickOutsideSearch() {
			this.set({
				showReferenceSearch: false
			})
		}
	},
	ondestroy() {
		this.removeProviders()
	},
	data() {
		return {
			showReferenceSearch: false
		}
	},
	components: {
		ReferenceSearch,
		StateLink,
		ClickWatcher,
	}
}
</script>
