<div class="background-border">
	<ReferenceSearchInput
		on:submit="onsubmit()"
		on:escape="cancel()"
		bind:value="inputValue"
		autofocus={true}
	/>
</div>

<style>.background-border {
	padding: 16px;
	background-color: #717171;
	border-radius: 8px;
}
</style>

<script>
import svelteQuerystringRouter from 'svelte-querystring-router'
const { navigate } = svelteQuerystringRouter

import ReferenceSearchInput from './ReferenceSearchInput.html'

import getTargetStateFromReference from 'lib/get-target-state-from-reference.js'

function navigationState(component) {
	const { matchingReference, mediator, haveNavigated, lastState, lastParams, lastAnchor } = component.get()

	console.log('something something navigate to', matchingReference)
	if (matchingReference) {
		const { anchor, stateName, params } = matchingReference

		const sameState = lastState === stateName
			&& lastParams.book === params.book

		if (!sameState) {
			if (anchor) {
				mediator.callSync('setAnchorAfterStateTransition', stateName, params, anchor)
			}

			mediator.callSync('stateGo', stateName, params, {
				replace: haveNavigated
			})

			component.set({
				haveNavigated: true
			})
		} else {
			const updateQuerystring = params.highlight !== lastParams.highlight
			const updateAnchor = anchor
				&& anchor !== lastAnchor
				&& document.getElementById(anchor)

			if (updateQuerystring || updateAnchor) {

				if (updateQuerystring) {
					navigate({
						parameters: {
							highlight: params.highlight
						},
						replace: haveNavigated
					})
				}

				if (updateAnchor) {
					window.history.replaceState({}, '', `#${anchor}`)
					const element = document.getElementById(anchor)
					element.scrollIntoView(true)
				}


				component.set({
					haveNavigated: true
				})
			}
		}

		component.set({
			lastState: stateName,
			lastParams: params,
			lastAnchor: anchor,
		})
	}
}

export default {
	data() {
		return {
			inputValue: '',
			lastState: null,
			lastParams: null,
			lastAnchor: null,
		}
	},
	computed: {
		matchingReference: ({ inputValue, currentBookId }) =>
			getTargetStateFromReference(inputValue, currentBookId)
	},
	onstate({ changed }) {
		if (changed.matchingReference) {
			navigationState(this)
		}
	},
	components: {
		ReferenceSearchInput,
	},
	methods: {
		cancel() {
			const haveNavigated = this.get().haveNavigated

			this.set({
				show: false,
				haveNavigated: false
			})

			if (haveNavigated) {
				window.history.back()
			}
		},
		onsubmit() {
			this.set({
				show: false
			})
		},
	}
}
</script>
