<ol>
	{#each chapterNumbersToDisplay as number}
		<li>
			{#if displayChapterMatchesCurrent(number)}
				<a href="#{ getChapterNumberId(currentChapter) }">
					<strong>{ currentChapter }</strong>
				</a>
			{:else}
				<a href="#{ getChapterNumberId(number) }">
					{ number }
				</a>
			{/if}
		</li>
	{/each}
</ol>

<style>ol {
	list-style: none;
	position: fixed;

	height: 100vh;
	width: 32px;
	margin: 0;
	padding: 0;

	top: 0;
	right: 0;

	display: -webkit-box;

	display: -ms-flexbox;

	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
	-webkit-box-pack: space-evenly;
	    -ms-flex-pack: space-evenly;
	        justify-content: space-evenly;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
}

li {
	-ms-flex-preferred-size: 16px;
	    flex-basis: 16px;
	-webkit-box-flex: 1;
	    -ms-flex-positive: 1;
	        flex-grow: 1;

	width: 100%;
	height: 16px;
	padding: 0;
}

a {
	width: 100%;
	height: 100%;
	font-size: 16px;
	font-family: "Lato", "Helvetica Neue", Helvetica, sans-serif;

	display: -webkit-box;

	display: -ms-flexbox;

	display: flex;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
}
</style>

<script>
import { getChapterNumberId } from 'lib/get-id.js'

const maxNumberOfChapterToDisplay = 15

export default {
	helpers: {
		getChapterNumberId
	},
	computed: {
		chapterCount: ({ chapterNumbers }) => chapterNumbers.length,
		chapterModulusToUse: ({ chapterCount }) => Math.ceil(chapterCount / maxNumberOfChapterToDisplay),
		chapterNumbersToDisplay: ({ chapterNumbers, chapterModulusToUse }) =>
			chapterNumbers.filter(number =>
				(number - 1) % chapterModulusToUse === 0
			),
		displayChapterMatchesCurrent: ({ chapterModulusToUse, currentChapter }) =>
			displayChapter => currentChapter
				&& currentChapter >= displayChapter
				&& currentChapter < (displayChapter + chapterModulusToUse)
	}
}
</script>
