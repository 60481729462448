<div class="container">
	<h1>
		Not Found
	</h1>
	{#if route}
		<p>No route for {route}</p>
	{/if}
	<p>
		<StateLink state="main.home">Home</StateLink>
	</p>
</div>

<style>.container {
	padding-left: .5rem;
	padding-right: .5rem;
}
@media screen and (min-width: 600px) {
	.container {
		padding-left: 1rem;
		padding-right: 1rem;
	}
}
@media screen and (min-width: 1000px) {
	.container {
		padding-left: 2rem;
		padding-right: 2rem;
	}
}
.container {
	padding-top: .5rem;
	padding-bottom: .5rem;
}
@media screen and (min-width: 600px) {
	.container {
		padding-top: 1rem;
		padding-bottom: 1rem;
	}
}
@media screen and (min-width: 1000px) {
	.container {
		padding-top: 2rem;
		padding-bottom: 2rem;
	}
}
</style>

<script>
import StateLink from 'component/StateLink.html'
export default {
	components: {
		StateLink
	}
}
</script>
